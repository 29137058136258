<template>
  <div class="p-2">
    <div class="text-6xl mt-10">404</div>
    <div class="text-lg mt-5 font-normal">Desculpe, pagina não encontrada.</div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
